// src/App.js
import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Header';
import Home from './Home';
import Register from './Register';
import Login from './Login';
import Logout from './Logout';
import {AuthProvider} from './AuthContext';

// Import other views/components here

function App() {
  return (
    <Router>
      <AuthProvider>
        <div className="App">
          <Header />
          <main>
            <Routes>
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login />}/>
              <Route path="/logout" element={<Logout />} />
              <Route path="*" element={<Home />} />
            </Routes>
          </main>
          <footer/>
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;
