import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext'; // assuming you have this context setup

function Logout() {
    const navigate = useNavigate();
    const { setIsLoggedIn } = useAuth();
    
    const [countdown, setCountdown] = useState(3);
    const [logoutStatus, setLogoutStatus] = useState('Logging out...');

    useEffect(() => {
        // Begin countdown
        if (countdown > 0) {
            const timer = setTimeout(() => {
                setCountdown(countdown - 1);
                setLogoutStatus(`Redirecting in ${countdown} seconds...`);
            }, 1000);
            return () => clearTimeout(timer);
        } 
        
        if (countdown === 0) {
            // Clearing token or user info from storage
            localStorage.removeItem('authToken');
            
            // Update global state or context
            setIsLoggedIn(false);

            navigate('/home');
        }
    }, [countdown, setIsLoggedIn, navigate]);

    return (
        <div>
            {logoutStatus}
        </div>
    );
}

export default Logout;
