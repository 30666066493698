import React from 'react';
import { Link } from 'react-router-dom';
import {useAuth} from './AuthContext';

function Header() {
  const {isLoggedIn, setIsLoggedIn } = useAuth();

  return (
    <header className="App-header">
      <nav className="nav-container">
        <div className="left-links">
          <Link to="/">Home</Link>
          <Link to="/challenges">Challenges</Link>
          <Link to="/scoreboard">Scoreboard</Link>
          <Link to="/users">Users</Link>
          <Link to="/teams">Teams</Link>
        </div>
        <div className="right-links">
          {!isLoggedIn ? (
            <>
              <Link to="/register">Register</Link>
              <Link to="/login">Login</Link>
            </>
          ) : (
            <>
              <Link to="/profile">Profile</Link>
              <Link to="/settings">Settings</Link>
              <Link to="/myteam">My Team</Link>
              <Link to="/logout">Logout</Link>
            </>
          )}
        </div>
      </nav>
    </header>
  );
}

export default Header;
