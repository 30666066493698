import React, { useState, useEffect } from 'react';
import {useNavigate} from 'react-router-dom';
import {useAuth} from './AuthContext';
import axios from 'axios';

function Login() {
    const [formData, setFormData] = useState({
        username: '',
        password: ''
    });

    const [loginStatus, setLoginStatus] = useState('');
    const [countdown, setCountdown] = useState(null);
    const {isLoggedIn, setIsLoggedIn} = useAuth();
    const Navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://localhost:8080/api/login', formData);
            if (response.data.token) {
                // Store the token in local storage (or other methods of your choice)
                localStorage.setItem('authToken', response.data.token);
                setLoginStatus('Login successful!');
                setIsLoggedIn(true);
                setCountdown(3);
            }
        } catch (error) {
            setLoginStatus('Login failed. Please check your credentials.');
        }
    };

    useEffect(() => {
        let timer;
        if (countdown > 0) {
            timer = setTimeout(() => {
                setCountdown(countdown - 1);
                setLoginStatus(`Login successful! Redirecting in ${countdown - 1} seconds...`);
            }, 1000);
        } else if (countdown === 0) {
            Navigate('/home');
        }
        return () => clearTimeout(timer);
    }, [countdown, Navigate]);

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <input 
                    type="username" 
                    name="username" 
                    placeholder="Username" 
                    value={formData.username} 
                    onChange={handleChange} 
                />
                <input 
                    type="password" 
                    name="password" 
                    placeholder="Password" 
                    value={formData.password} 
                    onChange={handleChange} 
                />
                <button type="submit">Login</button>
            </form>
            {loginStatus && <p>{loginStatus}</p>}
        </div>
    );
}

export default Login;
